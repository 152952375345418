export const AppRoutes = {
  // pages
  Index: { path: "/" },
  Home: { path: "/home" },
  SignUp: { path: "/signup" },
  SignIn: { path: "/signin" },
  Registration: { path: "/registration" },
  Stats: { path: "/organization-statistics" },
  OrgChart: { path: "/organogram" },
  OrgTree: { path: "/organization-tree" },
  Finance: { path: "/finance" },
  Payroll: { path: "/payroll" },
  People: { path: "/people" },
  Appointments: { path: "/appointments" },
  Recruitment: { path: "/recruitment" },
  ManagersHub: { path: "/managers-hub" },
  TeamSupervisorView: {
    path: "/managers-hub/team/:teamId",
    paramLessPath: "/managers-hub/team/",
    mainNav: "managers-hub",
  },
  MyProfile: { path: "/my-profile" },
  Admin: { path: "/admin" },
  Employee: {
    path: "/people/employee/:employeeId",
    paramLessPath: "/people/employee/",
    mainNav: "people",
  },
  BulkAddEmployees: { path: "/people/bulk-add-employees" },
  TimeOffRequests: {
    path: "/time-off-requests",
  },
  TimeOffRequestsReview: {
    path: "/time-off-requests/review",
  },
  Schedule: {
    path: "/people/schedules/:scheduleId",
    paramLessPath: "/people/schedules/",
    mainNav: "people",
  },
  TermsOfService: { path: "/terms-of-service" },
  PrivacyPolicy: { path: "/privacy-policy" },
  PasswordReset: { path: "/reset-password" },
  PasswordChange: { path: "/change-password" },
  SubscriptionExpired: { path: "/subscription-expired" },
  Staff: { path: "/staff" },
  StaffOrgView: {
    path: "/staff/organization/:organizationId",
    paramLessPath: "/staff/organization/",
  },
};
