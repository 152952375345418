import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  ListGroup,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import {
  appointmentReminder,
  priorityOptions,
  RepeatAppointmentConfigs,
  repeatTypes,
} from "../../../data/appointmentData";
import {
  priorityColors,
} from "../../../utils/functions";
import makeAnimated from "react-select/animated";
import dayjs from 'dayjs';
import { getNowPlusMins,DateToDayJs,getNearest15Minutes, combineDateTime, addMinutes} from "../../../utils/dateTime";
const animatedComponents = makeAnimated();

const AppointmentForm = ({
  handleSubmit,
  data,
  setData,
  onSubmit,
  handleEditData,
  isSelfCalendar,
  contextState
}) => {

  const today = new Date().toISOString().split("T")[0];
  const [currentTime, setCurrentTime] = useState("");

  const minDate = getNowPlusMins()
  const { darkMode } = contextState;
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [repeatEndDate, setRepeatEndDate] = useState(null);
  const [repeat, setRepeat] = useState(null);
  const [initialized, setInitialized] = useState(false); 


  useEffect(() => {
    if (!initialized) {
      if (data.is_past) {
        // Get current date and time
        const today = dayjs().format('YYYY-MM-DD');
        const currentTime = dayjs().format('HH:mm');
        const currentEndTime = dayjs().add(60, 'minute').format('HH:mm');
  
        // Update data with the current date and time
        setData((prev) => ({
          ...prev,
          startDate: today,
          startTime: currentTime,
          endDate: today,
          endTime: currentEndTime,
          start_datetime: `${today}T${currentTime}`,  // Combine date and time for start_datetime
          end_datetime: `${today}T${currentEndTime}`, // Combine date and time for end_datetime
          repeat_end_date: `${today}T${currentEndTime}`, // Default repeat end date if necessary
        }));
      }else {
        // Event is in the future, use the provided start and end datetimes
        const { start_datetime,repeat, end_datetime, repeat_end_date } = data;
  
        // Parse the datetimes into date and time parts
        const parsedStartDate = dayjs(start_datetime).format('YYYY-MM-DD');
        const parsedStartTime = dayjs(start_datetime).format('HH:mm');
        const parsedEndDate = dayjs(end_datetime).format('YYYY-MM-DD');
        const parsedEndTime = dayjs(end_datetime).format('HH:mm');
        const parsedRepeatEndDate = end_datetime > repeat_end_date ? end_datetime : dayjs(repeat_end_date).format('YYYY-MM-DD');
        // Set parsed values into state
        setStartDate(parsedStartDate);
        setStartTime(parsedStartTime);
        setEndDate(parsedEndDate);
        setEndTime(parsedEndTime);
        setRepeatEndDate(parsedRepeatEndDate);
        setRepeat(repeat);
      }
  
      // Mark the component as initialized to prevent further updates
      setInitialized(true);
    }
  }, [data, initialized]);
  
// New useEffect to watch for changes to startDate, startTime, endDate, and endTime
useEffect(() => {
  if (initialized ) {
    if (startDate && startTime && endDate && endTime) {
      const newStartDatetime = `${startDate}T${startTime}`;
      const newEndDatetime = `${endDate}T${endTime}`;

      setData((prev) => ({
        ...prev,
        start_datetime: newStartDatetime,
        end_datetime: newEndDatetime,
        repeat: false,
        repeat_end_date: newEndDatetime // Update repeat_end_date to match the end time
      }));
    }
    setInitialized(true);
  }
}, [startDate, startTime, endDate, endTime]);


  const isFormValid = () => {
    let requiredFields = ["title", "start_datetime", "end_datetime", "priority"]

    if (data.repeat) {
      requiredFields = [
        ...requiredFields,
        "repeat_option",
        "repeat_choice",
        "repeat_end_date",
      ]

      if (data.repeat_option) {
        requiredFields.push("second_repeat_choice")
      }
    }

    return requiredFields.every((field) => data[field])
  }

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: darkMode ? "#000" : "#fff",
      color: darkMode ? "#fff" : "#000",
      borderColor: darkMode ? "#555" : "#ccc",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: darkMode ? "#000" : "#fff",
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      backgroundColor: isSelected
        ? darkMode
          ? "#333"
          : "#ddd"
        : darkMode
        ? "#000"
        : "#fff",
      color: isSelected
        ? darkMode
          ? "#fff"
          : "#000"
        : darkMode
        ? "#fff"
        : "#000",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: darkMode ? "#fff" : "#000",
    }),
  };
  // Get current time in 'HH:MM' format, rounded to the nearest 15-minute interval
  useEffect(() => {
    const nearestTime = getNearest15Minutes();
    const formattedTime = nearestTime
      .toTimeString()
      .split(":")
      .slice(0, 2)
      .join(":");
    setCurrentTime(formattedTime);
  }, []);

  useEffect(() => {
    // Automatically set startDate to today if not set
    if (!data?.startDate) {
      setData((prev) => ({
        ...prev,
        startDate: today,
      }));
    }

    // Automatically set startTime to the nearest 60-minute interval if not set
    if (!data?.startTime) {
      setData((prev) => ({
        ...prev,
        startTime: currentTime,
        endTime: addMinutes(currentTime, 60), // Set default endTime 60 minutes after startTime
      }));
    }

    // Automatically set endDate to startDate if endDate is not set
    if (!data?.endDate) {
      setData((prev) => ({
        ...prev,
        endDate: prev.startDate || today,
      }));
    }
 
  }, [data, currentTime]);

  // Render Repeat Options
  const renderRepeatOptions = () => {
    const { repeat_option } = data;
    if (!repeat_option) return null;

    const repeatConfig = RepeatAppointmentConfigs[repeat_option];
    if (!repeatConfig) return null;

    // Daily Repeat Options
    if (repeat_option === "Daily") {
      return (
        <Form.Group
          className="pt-4"
          controlId="includeWeekends"
        >
          <Form.Label>{repeatConfig.options.label}</Form.Label>
          <Select
            styles={colourStyles}
            options={repeatConfig.options.choices.map((choice) => ({
              value: choice,
              label: choice,
            }))}
            value={{
              value: data.repeat_choice,
              label: data.repeat_choice,
            }}
            onChange={handleDailyChange}
          />
        </Form.Group>
      );
    }

    // Weekly and Every 2 weeks Repeat Options
    if (repeat_option === "Weekly" || repeat_option === "Every 2 weeks") {
      return (
        <Form.Group
          className="pt-4"
          controlId="repeatOptions"
        >
          <Form.Label>{repeatConfig.options.label}</Form.Label>
          <Select
            styles={colourStyles}
            isMulti
            closeMenuOnSelect={false}
            components={animatedComponents}
            options={repeatConfig.options.choices.map((day) => ({
              value: day,
              label: day,
            }))}
            value={
              Array.isArray(data.repeat_choice)
                ? data.repeat_choice.map((choice) => ({
                    value: choice,
                    label: choice,
                  }))
                : []
            }
            onChange={handleRepeatOptionsChange}
          />
        </Form.Group>
      );
    }

    // Monthly Repeat Options
    if (repeat_option === "Monthly") {
      return (
        <>
          {/* Select Monthly Repeat Option */}
          <Form.Group
            className="pt-4"
            controlId="monthlyRepeatOption"
          >
            <Form.Label>{repeatConfig.options.label}</Form.Label>
            <Select
              styles={colourStyles}
              options={repeatConfig.options.choices.map((option) => ({
                value: option,
                label: option,
              }))}
               closeMenuOnSelect={false}
              value={{
                value: data.repeat_choice,
                label: data.repeat_choice,
              }}
              onChange={handleMonthlyRepeatOptionChange}
            />
          </Form.Group>

          {/* Render Secondary Option based on the chosen repeat option */}
          {data.repeat_choice && (
            <Form.Group
              className="pt-4"
              controlId="secondaryOptions"
            >
              <Form.Label>
                {
                  repeatConfig.secondaryOptions[data.repeat_choice]
                    .label
                }
              </Form.Label>
              <Select
                styles={colourStyles}
                isMulti
                closeMenuOnSelect={false}
                components={animatedComponents}
                options={repeatConfig.secondaryOptions[
                  data.repeat_choice
                ].choices.map((option) => ({
                  value: option,
                  label: option,
                }))}
                value={
                  Array.isArray(data.second_repeat_choice)
                    ? data.second_repeat_choice.map((choice) => ({
                        value: choice,
                        label: choice,
                      }))
                    : []
                }
                onChange={handleMonthlyRepeatDaysChange}
              />
            </Form.Group>
          )}
        </>
      );
    }

    return null;
  };

  const getItemStyles = (priority) => {
    const isSelected = data.priority === priority;
    return {
      cursor: "pointer",
      backgroundColor: isSelected
        ? priorityColors[priority]
        : darkMode
        ? "#333" // Dark mode background for unselected items
        : "#fff", // Light mode background for unselected items
      color: isSelected
        ? "#fff" // Text color for selected items
        : darkMode
        ? "#ddd" // Text color in dark mode
        : "#000", // Text color in light mode
      borderColor: priorityColors[priority],
      border: `1px solid ${priorityColors[priority]}`,
      borderRadius: "4px",
      padding: "0.5rem 0.5rem",
      margin: "0 0.2rem",
    };
  };

  const getRepeatEndDateValue = (data, repeatEndDate) => {
    const latestEndDate = data.endDate > data.startDate ? data.endDate : data.startDate;
    const parsedRepeatEndDate = data.repeat_end_date ? dayjs(data.repeat_end_date).format('YYYY-MM-DD') : null;
    return parsedRepeatEndDate > latestEndDate 
      ? repeatEndDate ||  parsedRepeatEndDate
      : latestEndDate ;
  };
  
  const handleRepeatTypeChange = (selectedOption) => {
    const selectedType = selectedOption ? selectedOption.value : null;
    setData((prev) => ({
      ...prev,
      repeat_option: selectedType,
      repeat_choice: null,
      second_repeat_choice: [],
    }));
  };
  const handleRepeatOptionsChange = (selectedOptions) => {
    setData((prev) => ({
      ...prev,
      repeat_choice: selectedOptions
        ? selectedOptions.map((option) => option.value)
        : null,
    }));
  };
  const handleDailyChange = (selectedOption) => {
    setData((prev) => ({
      ...prev,
      repeat_choice: selectedOption ? selectedOption.value : null,
    }));
  };
  const handleMonthlyRepeatOptionChange = (selectedOption) => {
    setData((prev) => ({
      ...prev,
      repeat_choice: selectedOption ? selectedOption.value : null,
      second_repeat_choice: [], // Reset the secondary choice when option changes
    }));
  };
  const handleMonthlyRepeatDaysChange = (selectedOptions) => {
    setData((prev) => ({
      ...prev,
      second_repeat_choice: selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [],
    }));
  };

  const handlePriorityChange = (priority, event) => {
    event.preventDefault();
    setData((prev) => ({
      ...prev,
      priority: priority ?? "", // Set the clicked priority
    }));
  };
  const handleAppointmentReminder = (selectedOptions) => {
    setData((prev) => ({
      ...prev,
      // Store reminder_datetimes as an array of values (e.g., ["30 Minutes", "1 Hour"])
      reminder_datetimes: selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [],
    }));
  };

  const handleRepeatEndDateChange = (newRepeatEndDate) => {
    setData((prev) => ({
      ...prev,
      repeat_end_date: combineDateTime(newRepeatEndDate, prev.endTime), // Combine with end time
    }));
  };
  console.log("data", data)
  return (
    <Form onSubmit={handleSubmit}>
      {/* Title */}
      <Form.Group
        className="py-2"
        controlId="appointmentTitle"
      >
        <Form.Label>Appointment Title*</Form.Label>
        <Form.Control
          type="text"
          value={data?.title || ""}
          onChange={(e) =>
            setData((prev) => ({
              ...prev,
              title: e.target.value,
            }))
          }
        />
      </Form.Group>

      {/* Description */}
      <Form.Group
        className="py-4"
        controlId="appointmentDescription"
      >
        <Form.Label>Description / Notes (Optional)</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={data?.description || ""}
          onChange={(e) =>
            setData((prev) => ({
              ...prev,
              description: e.target.value,
            }))
          }
        />
      </Form.Group>

      {/* Location */}
      <Form.Group
        className="py-4"
        controlId="appointmentLocation"
      >
        <Form.Label>Appointment Location (Optional)</Form.Label>
        <Form.Control
          type="text"
          value={data?.location || ""}
          onChange={(e) =>
            setData((prev) => ({
              ...prev,
              location: e.target.value,
            }))
          }
        />
      </Form.Group>

      {/* Start Date & Time */}
      <Form.Group
        className="py-4"
        controlId="startdate"
      >
        <Form.Label>Start Date & Time*</Form.Label>
        <div className="d-flex justify-content-between">
          {/* Start Date */}
          <Form.Control
            type="date"
            min={today} // Prevent earlier dates
            value={data.startDate || startDate}
            onChange={(e) => {
              const startDate = e.target.value;
              setData((prev) => {
                // If endDate is earlier than startDate, adjust endDate to match startDate
                const adjustedEndDate =
                  new Date(prev.endDate) > new Date(data.startDate)
                    ? data.startDate
                    : prev.endDate;

                return {
                  ...prev,
                  startDate,
                  endDate: startDate, // Adjust endDate if necessary
                  start_datetime: combineDateTime(startDate, prev.startTime),
                  end_datetime: combineDateTime(startDate, prev.endTime),
                };
              });
            }}
            style={{ marginRight: "10px" }}
          />

          {/* Start Time */}
          <Form.Control
            type="time"
            step="900" // 15-minute intervals
            min={minDate} // Prevent times earlier than the current time today
            value={data.startTime || ""}
            onChange={(e) => {
              const startTime = e.target.value;
              const newEndTime = addMinutes(startTime, 60); // Automatically set endTime 15 minutes after startTime
              setData((prev) => ({
                ...prev,
                startTime,
                endTime: newEndTime, 
                start_datetime: combineDateTime(prev.startDate, startTime),
                end_datetime: combineDateTime(
                  prev.endDate || prev.startDate,
                  newEndTime
                ),
              }));
            }}
          />
        </div>
      </Form.Group>

      {/* End Date & Time */}
      <Form.Group
        className="py-4"
        controlId="appointmentEnd"
      >
        <Form.Label>End Date & Time*</Form.Label>
        <div className="d-flex justify-content-between">
          {/* End Date */}
          <Form.Control
            type="date"
            value={data.endDate > data.startDate ? data.endDate : data.startDate  }
            min={data.startDate || startDate} // End date cannot be earlier than start date
            onChange={(e) => {
              const endDate = e.target.value;
              setData((prev) => ({
                ...prev,
                endDate,
                end_datetime: combineDateTime(endDate, prev.endTime),
              }));
            }}
            style={{ marginRight: "10px" }}
          />
          {/* End Time */}
          <Form.Control
            type="time"
            step="1200" // 15-minute intervals
            min={data.startTime || data.endTime}
            value={data.endTime || endTime}
            onChange={(e) => {
            const endTime = e.target.value;
            // Ensure EndTime is greater than StartTime
            if (data.startTime >= endTime) {
              setData((prev) => ({
                ...prev,
                endTime: addMinutes(data.startTime, 15), // Set to minimum valid end time
                end_datetime: combineDateTime(prev.endDate, addMinutes(data.startTime, 15)),
              }));
            } else {
              setData((prev) => ({
                ...prev,
                endTime,
                end_datetime: combineDateTime(prev.endDate, data.endTime),
              }));
            }
          }}
          />
        </div>
      </Form.Group>

      <div style={{ border: "1px solid black", padding: "10px" }}>
        {/* Repeat Switch */}
        <Form.Group
          className="py-4"
          controlId="repeatSwitch"
        >
          <div className="d-flex align-items-center">
            <Form.Check
              type="switch"
              checked={repeat || data?.repeat}
              size={40}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  repeat: e.target.checked, // Toggle repeat status
                }))
              }
            />
            <Form.Label className="mb-0 me-4">Repeat Appointment</Form.Label>
          </div>
          <span className="x-small-text text-muted">
            Daily, Weekly, Monthly etc
          </span>
        </Form.Group>

        {/* Repeat Type */}
        {data.repeat && !data.hide_repeat_options && (
          <>
            <Form.Group
              className="py-4"
              controlId="repeat_option"
            >
              <Form.Label>Select Repeat Option*</Form.Label>
              <Select
                styles={colourStyles}
                options={repeatTypes}
         
                value={
                  data?.repeat_option
                    ? {
                        value: data?.repeat_option,
                        label: data?.repeat_option,
                      }
                    : null
                }
                onChange={handleRepeatTypeChange}
              />
            </Form.Group>
            {renderRepeatOptions()}
          </>
        )}

        {/* Repeat End date */}
        {data?.repeat  && !data.hide_repeat_options && (
          <Form.Group
            className="py-4"
            controlId="repeat_end_date"
          >
            <Form.Label>Repeat End Date*</Form.Label>
            <div className="d-flex justify-content-between">
              <Form.Control
                type="date"
                name="repeat_end_date"
                value={getRepeatEndDateValue(data, repeatEndDate)}
                min={data.endDate > data.startDate ? data.endDate : data.startDate}
                onChange={(e) => handleRepeatEndDateChange(e.target.value)}
        
                style={{ marginRight: "10px" }}
              />
            </div>
          </Form.Group>
        )}
      </div>

      {/* Priority */}

      <Form.Group
        className="py-4"
        controlId="dataPriority"
      >
        <Form.Label>Priority*</Form.Label>

        {/* Horizontal ListGroup */}
        <ListGroup horizontal>
          {priorityOptions.map((option) => (
            <ListGroup.Item
              key={option.value}
              action
              onClick={(e) => handlePriorityChange(option.value, e)}
              style={getItemStyles(option.value)} // Apply dynamic styles
            >
              {option.label}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Form.Group>

      {/* Appointment Reminder Types */}
      <Form.Group
        className="py-4"
        controlId="reminder_datetimes"
      >
        <Form.Label>Appointment Reminder Times</Form.Label>
        <Select
          styles={colourStyles}
          isMulti
          closeMenuOnSelect={false}
          components={animatedComponents}
          options={appointmentReminder}
          value={
            Array.isArray(data?.reminder_datetimes)
              ? data?.reminder_datetimes
                  .filter((reminder) =>
                    appointmentReminder.some(
                      (option) => option.value === reminder
                    )
                  )
                  .map((reminder) => ({
                    value: reminder,
                    label: reminder,
                  }))
              : []
          }
          onChange={(selectedOptions) =>
            handleAppointmentReminder(selectedOptions)
          }
        />
        <span className="x-small-text text-muted">
          (Optional) select the time you'd like to recieve reminder before your
          appointment begins.
        </span>
      </Form.Group>

      {/* Send Email Switch */}
      {data?.reminder_datetimes?.length > 0 && (
        <Form.Group
          className="py-4 "
          controlId="send_email_reminder"
        >
          <div className="d-flex align-items-center">
            <Form.Check
              type="switch"
              checked={data?.send_email_reminder}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  send_email_reminder: e.target.checked,
                }))
              }
            />
            <Form.Label className="mb-0 me-3">Send Email Reminder</Form.Label>
          </div>
          <span className="x-small-text text-muted">
            Push notification reminder will always be sent
          </span>
        </Form.Group>
      )}
      {data.is_edit && data.apply_to_series && (
          <div>
          <Form.Group
          className="py-4"
          controlId="apply_series"
        >
          <div className="d-flex align-items-center">
    
           <Form.Label className="mb-0 me-4">Applying to Series</Form.Label>
          </div>
          <span className="x-small-text text-muted">
          The changes you make here will apply to all the future data in this series.
          </span>
        </Form.Group>
          </div>
        )}

      {/* Self Calendar */}
      {!isSelfCalendar && (
        <Form.Group
          className="py-4"
          controlId="notify_owner"
        >
          <div className="d-flex align-items-center">
            <Form.Check
              type="switch"
              checked={data?.notify_owner}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  notify_owner: e.target.checked,
                }))
              }
            />
            <Form.Label className="mb-0 me-4">Notify Calendar Owner</Form.Label>
          </div>
          <span className="x-small-text text-muted">
            Let the owner know that you have created this appointment
          </span>
        </Form.Group>
      )}

      <div>
        {/* Thin line above the buttons */}
        <hr style={{ borderTop: "2px solid #ddd", marginTop: "20px" }} />

  
          <Row>
            {data?.is_past ? (
              <Col xs={12}>
                <Button
                  variant="primary"
                  onClick={() => 
                    handleEditData(data.id, data)
                  }
                  style={{ width: "100%" }}
                >
                  Copy Appointment
                </Button>
              </Col>
            ) : (
              <>
                <Col xs={12}>
                  <Button
                    variant="primary"
                    disabled={!isFormValid()}
                    onClick={() => {
                    if(data.is_edit){
                      handleEditData(data.id, data)
                    }
                    else {
                      onSubmit({
                        ...data,
                      })
                    }
                    }
                  }
                    style={{ width: "100%" }}
                  >
                   {data.is_edit  ? "Submit Appointment" : "Add Appointment"}
                  </Button>
                </Col>
              </>     
            )}
        </Row>
      </div>
    </Form>
  );
};

export default AppointmentForm;
