import React, { useContext, useEffect, useState } from "react";
import { LoadingView } from "../utils/components";
import DashboardPageContainer from "./components/DashboardPageContainer";
import {
  toQueryParams,
  toTitleCase,
  updateObjectState,
  useOffCanvas,
} from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { EmployeeWorkInfo } from "./components/EmployeeManagement";
import EmployeeForm from "./Forms/Employee";
import { FaChevronLeft, FaInfoCircle } from "react-icons/fa";
import { AppRoutes } from "../utils/appRoutes";
import { Alert, Button, Tab, Tabs } from "react-bootstrap";
import { TeamHighlight } from "./components/Team";
import AttendanceManagement from "./components/Employee/AttendanceManagement";
import ManageProfile from "./components/Employee/ManageProfile";
import ManageCompensation from "./components/Employee/ManageCompensation";
import ManageTimeOff from "./components/Employee/ManageTimeOff";
import { formatDateStrings, getUTCDateTime } from "../utils/dateTime";
import PageMetaTags from "./components/Shared/PageMetaTags";
import EmployeeOrganizations from "./components/Employee/EmployeeOrganizations";
import EducationAndSkillsView from "./components/Employee/EducationAndSkillsView";
import DocumentsView from "./components/Employee/DocumentsView";

function EmployeeManagement({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
}) {
  const { contextState, updateContextState } = useContext(AppContext);
  const globalPermissions = contextState.permissions || {};
  const { showOffCanvas } = useOffCanvas();

  const navigate = useNavigate();

  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value });
  };
  const { employeeId } = useParams();

  const initData = {
    current_month: new Date().getUTCMonth().toString(),
    requested_time_off_data: false,
    requested_attendance_data: false,
    requested_organizations_data: false,
  };

  useEffect(() => {
    getEmployeeInfoInfo();
    setData(initData);
  }, [employeeId]);

  const [data, setData] = useState({
    ...initData,
  });
  const [activeTab, setActiveTab] = useState("profile_info");
  const [configs, setConfigs] = useState({ teams: [], time_off_policies: [] });
  const [employee, setEmployee] = useState({
    id: null,
    available_time_off: {},
  });
  const [showSecondLoader, setShowSecondLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [viewedAttendanceRecords, setViewedAttendanceRecords] = useState({});

  const tabPermissions = {
    profile_info: permissions.VIEW_EMPLOYEE_DATA,
    compensationInfo: permissions.VIEW_COMPENSATION,
    timeOff: permissions.VIEW_ATTENDANCE,
    attendance: permissions.VIEW_ATTENDANCE,
    expenses: permissions.VIEW_EXPENSE_REQUESTS,
    performance: permissions.VIEW_EMPLOYEE_PERFORMANCE,
    assets: permissions.VIEW_ASSET_ASSIGNMENT,
    teams: permissions.VIEW_EMPLOYEE_DATA,
    organizations: permissions.VIEW_EMPLOYEE_DATA,
  };

  const getEmployeeInfoInfo = async () => {
    getRequest(`/employee/${employeeId}`, setIsLoading, (response) => {
      updateObjectState(setConfigs, response.configs);
      const emp = response.employee;
      updateObjectState(setEmployee, emp);
      setPermissions(
        emp.is_inactive
          ? {
              VIEW_EMPLOYEE_DATA: globalPermissions.VIEW_EMPLOYEE_DATA,
            }
          : globalPermissions
      );
    });
  };

  useEffect(() => {
    // if (!configs.time_off_policies) return;
    if (searchParams.get("activeTab")) {
      activateTab(searchParams.get("activeTab"));
    }
  }, [configs, employee.id]);

  const getAttendances = async (nav) => {
    let viewed = viewedAttendanceRecords[nav.start_date];
    let attendances = {};
    let navigation = {};
    let attendance_info = {};
    if (viewed) {
      attendances = { ...viewed.attendances };
      navigation = { ...viewed.navigation };
      attendance_info = { ...viewed.attendance_info };
    } else {
      await getRequest(
        `/employee/attendance/${employee.id}?${toQueryParams(nav)}`,
        setShowSecondLoader,
        (response) => {
          attendances = { ...response.attendances };
          navigation = response.navigation;
          attendance_info = response.attendance_info;

          updateObjectState(setViewedAttendanceRecords, {
            [`${navigation.active_month}`]: {
              attendances,
              navigation,
              attendance_info,
            },
          });
        }
      );
    }
    updateObjectState(setEmployee, {
      attendances,
      navigation,
      attendance_info,
    });
  };

  const activateTab = (key) => {
    setActiveTab(key);
    updateQueryParam("activeTab", key);
    if (key === "timeOff" && !data.requested_time_off_data) {
      getRequest(
        `/employee/available-time-off/${employeeId}`,
        setShowSecondLoader,
        (response) => {
          updateObjectState(setConfigs, {
            time_off_policies: response.time_off_policies,
          });
          updateObjectState(setEmployee, {
            available_time_off: response.available_time_off,
          });
          updateObjectState(setData, {
            requested_time_off_data: true,
          });
        }
      );
    }

    if (key === "attendance" && !data.requested_attendance_data) {
      getRequest(
        `/employee/attendance/${employeeId}`,
        setShowSecondLoader,
        (response) => {
          // console.log(response.navigation);
          // console.log(getDateTime(response.navigation.active_month));
          updateObjectState(setEmployee, {
            attendances: response.attendances,
            navigation: response.navigation,
            last_attendance: response.last_attendance,
            attendance_info: response.attendance_info,
          });
          updateObjectState(setData, {
            requested_attendance_data: true,
          });
          updateObjectState(setViewedAttendanceRecords, {
            [response.navigation.active_month]: {
              attendances: response.attendances,
              navigation: response.navigation,
              attendance_info: response.attendance_info,
            },
          });
        }
      );
    }

    if (key === "organizations" && !data.requested_organizations_data) {
      getRequest(
        `/employee/organizations/${employeeId}`,
        setShowSecondLoader,
        (response) => {
          updateObjectState(setEmployee, {
            organizations: response.organizations,
          });
          updateObjectState(setConfigs, {
            other_organizations: response.other_organizations,
          });
          updateObjectState(setData, {
            requested_organizations_data: true,
          });
        }
      );
    }
  };

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div className={"mb-5"}>
          <PageMetaTags title={`Employee | ${toTitleCase(activeTab)}`} />
          <h6
            className={"mb-3 pointer"}
            onClick={() => {
              navigate(AppRoutes.People.path + "?activeTab=people");
            }}
          >
            <FaChevronLeft /> People
          </h6>

          {permissions.VIEW_EMPLOYEE_DATA && (
            <>
              <h3 className={"mb-3"}>Employee Profile</h3>
              <div className={"mt-4"}>
                <EmployeeWorkInfo
                  employee={employee}
                  canManage={permissions.MANAGE_EMPLOYEE_DATA}
                  allowSupervisorClick={permissions.VIEW_EMPLOYEE_DATA}
                  onEditClick={() => {
                    updateContextState({
                      formData: formatDateStrings({ ...employee }),
                    });
                    showOffCanvas({
                      title: `Update ${employee.full_name}`,
                      subtitle: `Add a new employee to your organization`,
                      component: (
                        <EmployeeForm
                          onSubmit={(updateData) => {
                            const formData = new FormData();
                            if (updateData.profile_image_url) {
                              const image = updateData.profile_image;
                              delete updateData.profile_image;
                              formData.append("image", image);
                            }
                            formData.append(
                              "inputs",
                              JSON.stringify(updateData)
                            );
                            patchRequest(
                              `/employee/${employeeId}`,
                              setIsLoading,
                              (response) => {
                                updateObjectState(
                                  setEmployee,
                                  response.employee
                                );
                              },
                              formData,
                              true
                            );
                          }}
                          showAccessSwitch={true}
                          configs={configs}
                        />
                      ),
                    });
                  }}
                />
              </div>
            </>
          )}

          {globalPermissions.MANAGE_EMPLOYEE_DATA && employee.is_inactive && (
            <Alert variant={"danger"} className={"mt-3"}>
              <div>
                <FaInfoCircle className={"me-3"} size={25} />
                This employee's status is <strong>{employee.status}</strong> and
                is now inactive. You can only view their profile.
              </div>
            </Alert>
          )}

          <Tabs
            activeKey={activeTab}
            className={"mb-2 mt-3 sticky-top bg-body"}
            variant={"underline"}
            onSelect={(key) => activateTab(key)}
          >
            {permissions.VIEW_EMPLOYEE_DATA && (
              <Tab eventKey="profile_info" title="Profile">
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <ManageProfile
                        employee={employee}
                        setEmployee={setEmployee}
                        getRequest={getRequest}
                        patchRequest={patchRequest}
                        putRequest={putRequest}
                        deleteRequest={deleteRequest}
                        permissions={permissions}
                        configs={configs}
                        loaderCallback={setShowSecondLoader}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            {permissions.VIEW_COMPENSATION && (
              <Tab eventKey="compensationInfo" title="Compensation">
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <ManageCompensation
                        employee={employee}
                        setEmployee={setEmployee}
                        permissions={permissions}
                        configs={configs}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        loaderCallback={setShowSecondLoader}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            {permissions.VIEW_ATTENDANCE && (
              <Tab eventKey="timeOff" title="Time Off">
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <ManageTimeOff
                        employee={employee}
                        setEmployee={setEmployee}
                        permissions={permissions}
                        configs={configs}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        getRequest={getRequest}
                        loaderCallback={setShowSecondLoader}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            {permissions.VIEW_ATTENDANCE && (
              <Tab eventKey="attendance" title="Attendance">
                {/* Attendance Content */}
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      {data.requested_attendance_data && (
                        <AttendanceManagement
                          employee={employee}
                          setEmployee={setEmployee}
                          getRequest={getRequest}
                          loaderCallback={setShowSecondLoader}
                          navCallback={(nav) => {
                            getAttendances(nav);
                          }}
                          permissions={permissions}
                          contextState={contextState}
                          putRequest={putRequest}
                          patchRequest={patchRequest}
                          deleteRequest={deleteRequest}
                        />
                      )}
                    </>
                  }
                />
              </Tab>
            )}

            {/*{permissions.VIEW_EXPENSE_REQUESTS && (*/}
            {/*  <Tab eventKey="expenses" title="Expenses">*/}
            {/*    /!* Performance Content *!/*/}
            {/*  </Tab>*/}
            {/*)}*/}

            {/*{permissions.VIEW_EMPLOYEE_PERFORMANCE && (*/}
            {/*  <Tab eventKey="performance" title="Performance">*/}
            {/*    /!* Performance Content *!/*/}
            {/*  </Tab>*/}
            {/*)}*/}

            {/*{permissions.VIEW_ASSET_ASSIGNMENT && (*/}
            {/*  <Tab eventKey="assets" title="Assets">*/}
            {/*    /!* Assets Content *!/*/}
            {/*  </Tab>*/}
            {/*)}*/}

            {permissions.VIEW_EMPLOYEE_DATA && (
              <Tab eventKey="teams" title="Teams">
                {configs.teams.map((team, index) => (
                  <TeamHighlight
                    key={index}
                    team={team}
                    onMakePrimaryClick={
                      !permissions.MANAGE_EMPLOYEE_DATA ||
                      team.supervisor_employee_id === employee.id
                        ? null
                        : () => {
                            getRequest(
                              `/employee/primary=team/${employee.id}/${team.id}`,
                              setShowSecondLoader,
                              (response) => {
                                updateObjectState(setConfigs, {
                                  teams: response.teams,
                                });
                                updateObjectState(
                                  setEmployee,
                                  response.employee
                                );
                              },
                              true
                            );
                          }
                    }
                  />
                ))}
              </Tab>
            )}

            {permissions.VIEW_EMPLOYEE_DATA && (
              <Tab eventKey="organizations" title="Organizations">
                {/* Assets Content */}
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <EmployeeOrganizations
                      employee={employee}
                      setEmployee={setEmployee}
                      postRequest={postRequest}
                      loaderCallback={setShowSecondLoader}
                      permissions={permissions}
                      putRequest={putRequest}
                      patchRequest={patchRequest}
                      getRequest={getRequest}
                      deleteRequest={deleteRequest}
                      configs={configs}
                      setCongigs={setConfigs}
                      s
                    />
                  }
                />
              </Tab>
            )}

            {permissions.VIEW_EMPLOYEE_DATA && (
              <Tab eventKey="education_&_skills" title="Education & Skills">
                {/* Assets Content */}
                <LoadingView
                  isLoading={
                    showSecondLoader && activeTab === "education_&_skills"
                  }
                  centerLoader={false}
                  view={
                    <>
                      <EducationAndSkillsView
                        employee={employee}
                        setEmployee={setEmployee}
                        configs={configs}
                        purRequest={putRequest}
                        patchRequest={patchRequest}
                        permissions={permissions}
                        loaderCallback={setShowSecondLoader}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            {permissions.VIEW_EMPLOYEE_DATA && (
              <Tab eventKey="documents" title="Documents">
                {/* Assets Content */}
                <LoadingView
                  isLoading={showSecondLoader && activeTab === "documents"}
                  centerLoader={false}
                  view={
                    <>
                      <DocumentsView
                        employee={employee}
                        setEmployee={setEmployee}
                        configs={configs}
                        purRequest={putRequest}
                        patchRequest={patchRequest}
                        permissions={permissions}
                        loaderCallback={setShowSecondLoader}
                      />
                    </>
                  }
                />
              </Tab>
            )}
          </Tabs>
        </div>
      }
    />
  );
}

function EmployeeInfo() {
  return <DashboardPageContainer PageComponent={EmployeeManagement} />;
}

export default EmployeeInfo;
